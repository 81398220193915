import React, { useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Hero from '../components/heroBanner/heroBanner'
import { SsyLink } from '../components/ssyLink/ssyLink'
import style from '../assets/css/unsubscribe.module.css'
const UnsubscribePage = (props) => {
  const intl = useIntl()

  useEffect(() => {
    let eventId = getEventId()
    if (eventId) {
      fetch(
        `${process.env.GATSBY_API_URL}/events/${eventId}/reminderoff?api-version=2.0`
      )
        .then(res => console.log(res))

        .catch(e => console.log(e))
    }
  }, [])

  const getEventId = () => {
    return window.location.search.replace('?event=', '')
  }

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'UNSUBSCRIBE_META_TITLE' })}
        lang={intl.formatMessage({ id: 'MAIN_LANGUAGE' })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: 'UNSUBSCRIBE_META_DESCRIPTION' })}
      />
      <Hero title={intl.formatMessage({ id: 'UNSUBCRIBE_TITLE' })} align="center" />
      <section className={style.container}>
        <p>
          {intl.formatMessage({ id: 'UNSUBSCRIBE_COPY' })}
        </p>
        <SsyLink
          type="primary"
          internal={false}
          to={`${process.env.GATSBY_PUBLIC_URL}/${intl.formatMessage({
            id: 'MAIN_LANGUAGE',
          })}/`}
          label={intl.formatMessage({ id: 'UNSUBSCRIBE_BUTTON' })}
        />
      </section>
    </Layout>
  )
}

export default UnsubscribePage
